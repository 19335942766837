'use client';

import React, { useEffect, useState } from 'react';

// components
import ErrorView from 'scenes/Error/Error';
import NordicsError from 'scenes/Error/NordicsError';

// constants
import { THEME_NAMES } from 'themes';

const pages = {
  [THEME_NAMES.DEFAULT]: ErrorView,
  [THEME_NAMES.GDDS]: ErrorView,
  [THEME_NAMES.NORD]: NordicsError,
  [THEME_NAMES.KOLO]: NordicsError,
  [THEME_NAMES.TWYFORD]: NordicsError,
};

// eslint-disable-next-line import/no-unused-modules -- used by nextjs
export default function ErrorBoundary() {
  const [theme, setTheme] = useState<keyof typeof THEME_NAMES | undefined>(undefined);

  const goBack = () => {
    if (history.length > 1) {
      history.back();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  useEffect(() => {
    fetch('/api/500')
      .then((res) => res.json())
      .then((data) => {
        setTheme(data.theme);
      })
      .catch(() => {
        setTheme(THEME_NAMES.DEFAULT);
      });
  }, []);

  if (!theme) {
    return null;
  }

  const Page = pages[theme];

  return <Page goBack={goBack} />;
}
